import request from '@/utils/request'

// 查询采购订单
export function findAgentPurchaseOrders(params) {
  return request({
    url: `/agent_purchase_orders`,
    method: 'get',
    params: params
  })
}

// 查询订单详情
export function findAgentPurchaseOrder(id) {
  return request({
    url: `/agent_purchase_orders/${id}`,
    method: 'get'
  })
}

// 查询订单状态
export function findAgentPurchaseOrderStatusOptions() {
  return request({
    url: `/agent_purchase_orders/status_options`,
    method: 'get'
  })
}

// 完成订单
export function finishAgentPurchaseOrder(agentPurchaseOrderId, data) {
  return request({
    url: `/agent_purchase_orders/${agentPurchaseOrderId}/finish`,
    method: 'post',
    data
  })
}
