<template>
  <div>
    <search-agent-purchase-order @submit="submitSearch" />

    <a-spin :spinning="loading">
      <div v-if="isDataExist">
        <div
          v-for="order in data"
          :key="order.id"
        >
          <div class="simiot-card-box" style="margin-bottom: 20px">
            <a-row class="order-header">
              <a-col :span="16">
                <a-row>
                  <a-col :span="8">
                    <div v-if="isShowMenu('agent_purchase_orders/show')">
                      <a @click="showInfoModal(order.id)">{{ order.order_no }}</a>
                    </div>
                    <div v-else>
                      {{ order.order_no }}
                    </div>
                  </a-col>

                  <a-col :span="16" class="agent-name">
                    {{ order.agent_name }}
                  </a-col>
                </a-row>

                <a-row>
                  <a-col :span="8">
                    {{ order.created_at }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.status }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.pickup_way }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.card_count }}
                  </a-col>
                  <a-col :span="4">
                    ￥ {{ order.order_fee | formatCurrency }}
                  </a-col>
                </a-row>
              </a-col>

              <a-col :span="8">
                <div class="simiot-table-operation-buttons">
                  <a-space>
                    <a-button
                      type="primary"
                      v-if="order.status_slug === 'waiting_delivery' && isShowMenu('agent_purchase_orders/deliver')"
                      @click="showDeliveryModal(order.id)"
                      @completed="fetchData"
                    >发货</a-button>

                    <a-button
                      type="primary"
                      v-if="order.status_slug === 'delivered' && isShowMenu('agent_purchase_orders/deliver')"
                      @click="showModifyDeliveryModal(order.id)"
                      @completed="fetchData"
                    >修改发货信息</a-button>

                    <finish-agent-purchase-order
                      v-if="order.status_slug === 'delivered' && isShowMenu('agent_purchase_orders/finish')"
                      :id="order.id"
                      @completed="fetchData"
                    />
                  </a-space>
                </div>
              </a-col>
            </a-row>

            <agent-purchase-items-list
              :items="order.agent_purchase_items"
              :loading="loading"
              @completed="fetchData"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <a-empty />
      </div>
    </a-spin>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agent-purchase-order
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
      @completed="fetchData"
    />

    <!-- 发货模态框 -->
    <deliver-agent-purchase-order
      v-if="isShowDeliverModal"
      :visible.sync="isShowDeliverModal"
      :id="deliveringId"
      @completed="fetchData"
    />

    <!-- 修改发货信息模态框 -->
    <modify-deliver-agent-purchase-order
      v-if="isShowModifyDeliverModal"
      :visible.sync="isShowModifyDeliverModal"
      :id="modifyingDeliveringId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentPurchaseOrders } from '@/api/agent_purchase_order'
import AgentPurchaseItemsList from '@/views/agent_purchase_items/index'
import SearchAgentPurchaseOrder from '@/views/agent_purchase_orders/Search'
import FinishAgentPurchaseOrder from '@/views/agent_purchase_orders/Finish'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AgentPurchaseOrderList',
  components: {
    Pagination,
    AgentPurchaseItemsList,
    SearchAgentPurchaseOrder,
    FinishAgentPurchaseOrder,
    DeliverAgentPurchaseOrder: () => import('@/views/agent_purchase_orders/Deliver'),
    ModifyDeliverAgentPurchaseOrder: () => import('@/views/agent_purchase_orders/ModifyDeliver'),
    ShowAgentPurchaseOrder: () => import('@/views/agent_purchase_orders/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      deliveringId: 0, // 正在发货的订单id
      modifyingDeliveringId: 0, // 正在修改发货信息的订单id
      showingId: 0, // 正在显示详情的订单id
      isShowInfoModal: false,
      isShowModifyDeliverModal: false,
      isShowDeliverModal: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    if (this.$store.getters.userRole === 'business') {
      this.query = Object.assign({},
        { status: ['checking'] })
    } else if (this.$store.getters.userRole === 'storekeeper') {
      this.query = Object.assign({},
        { status: ['processing', 'waiting_delivery', 'delivered'] })
    }
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '项目编号',
          dataIndex: 'id'
        }
      ]
    },
    agentId() {
      return this.$store.getters.userAgentId
    },
    isDataExist() {
      return this.data.length > 0
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    showDeliveryModal(recordId) {
      this.deliveringId = recordId
      this.isShowDeliverModal = true
    },

    showModifyDeliveryModal(recordId) {
      this.modifyingDeliveringId = recordId
      this.isShowModifyDeliverModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentPurchaseOrders(Object.assign(
        { agent_id: this.agentId }, this.query)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.order-header {
  margin-bottom: 5px;
  color: #8b8b8b;

  .agent-name {
    color: #5d5d5d;
  }
}
</style>
