<template>
  <div>
    <a-button type="primary" @click="showFinishConfirm">完成</a-button>
  </div>
</template>

<script>
import { finishAgentPurchaseOrder } from '@/api/agent_purchase_order'
export default {
  name: 'FinishAgentPurchaseOrder',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  methods: {
    showFinishConfirm() {
      const thisFinish = this
      this.$confirm({
        title: '确定对此订单进行完成操作吗？\n 操作后将无法修改订单信息?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          finishAgentPurchaseOrder(thisFinish.id).then((res) => {
            if (res.code === 0) {
              // 告知父组件已完成
              thisFinish.$emit('completed')
            }
          })
        }
      })
    }
  }
}
</script>

